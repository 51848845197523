//Backend URL:

//local
// export const BASE_URL = "http://127.0.0.1:8181";

//Stagging
export const BASE_URL = "http://142.44.247.133:8181";

//Live
//export const BASE_URL = "https://api-dmb.virtualdev.in.net";
